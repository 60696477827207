<template>
  <section class="cards container" ref="cardsection">

      <h3 class="header5" v-if="slice.primary.title">
        {{ $prismic.asText(slice.primary.title) }}
      </h3>

      <div class="grid" v-if="slice.variation != 'simpleList'">
        <div :class="slice.items.length > 3 ? 'quarter' : 'third'"
          v-for="item in slice.items"
          :key="item.title[0].text"
        > 
          <!-- With Link -->
          <NuxtLink 
            v-if="item.link.link_type != 'Any'" :to="item.link.link_type == 'Web' ? item.link.url : `/${item.link.uid}`"
            @mouseenter="soundStore.playHover" 
            @click="soundStore.playClick"
          >
            <div ref="card" class="card">

              <lord-icon
                v-if="item.icon_id"
                :src="`https://cdn.lordicon.com/${item.icon_id}.json`"
                trigger="loop"
                delay="1000"
                colors="primary:#fd0079,secondary:#1b1091"
                style="width:80px;height:80px"
                loading="lazy"
              >
              </lord-icon>

              <PrismicImage v-else :field="item.icon" />

              <h3 class="header4" v-html="item.title[0].text"></h3>
              <prismic-rich-text :field="item.description" />
            </div>
          </NuxtLink>
          
          <!-- No Link -->
          <div ref="card" class="card" v-else>

            <lord-icon
              v-if="item.icon_id"
              :src="`https://cdn.lordicon.com/${item.icon_id}.json`"
              trigger="loop"
              delay="1000"
              colors="primary:#fd0079,secondary:#1b1091"
              style="width:80px;height:80px"
              loading="lazy"
            >
            </lord-icon>

            <PrismicImage v-else :field="item.icon" />

            <h3 class="header4" v-html="item.title[0].text"></h3>
            <prismic-rich-text :field="item.description" />
          </div>

        </div>
      </div>

      <div class="grid list" v-else>
        <div :class="slice.items.length > 3 ? 'quarter' : 'third'"
          v-for="item in slice.items"
          :key="item.title[0].text"
        >
          <span class="arrow">›</span> <h4 class="header4" v-html="item.title[0].text"></h4>
          <prismic-rich-text v-if="item.description" :field="item.description" />
        </div>
      </div>

  </section>
</template>

<script setup>
  import { gsap } from 'gsap'
  import { ScrollTrigger } from 'gsap/ScrollTrigger'
  gsap.registerPlugin(ScrollTrigger)

  const soundStore = useSoundStore()
  
  const props = defineProps(['slice'])
  const cardsection = ref()
  const card = ref()

  onMounted(() => {

    if(card.value) {
      gsap.from(card.value, {
        scrollTrigger: {
          trigger: cardsection.value,
          start: 'top 80%',
          end: 'top center',
          scrub: 0.2
        },
        top: '+=200px',
        duration: 0.5,
        stagger: 0.2,
      })
    }

  });
  
</script>

<style lang="scss" scoped>
.cards {
  background: var(--color-white);
  color: var(--color-black);
  overflow: hidden;

  a {
    text-decoration: none;

    .card:hover {
      background: var(--color-black);
      color: var(--color-white);
    }
  }

  .card {
    min-height: calc(100% - 80px);
    position: relative;
    padding: 40px;
    background: #FFF;
    transition: all 0.3s;
    color: var(--color-black);
    margin-bottom: 20px;
    transform-origin: 50% 50%;
    box-shadow: var(--shadow-drop);
    border-radius: var(--global-box-radius);
  }
}


.arrow {
  float: left;
  margin-right: 20px;
  margin-top: 10px;
  color: var(--color-primary);
  font-size: 30px;
} 

/* Tablet */
@media only screen and (max-width: 991px) {
  .card {
    margin-bottom: 20px;
  }

}

/* Mobile */
@media only screen and (max-width: 767px) {
  .card {
    margin-bottom: 20px;
  }

}
</style>

